import gsap from 'gsap'
import slider from './slider.js';

const modalRoot = document.querySelector('#js-modal');
const modalInner = document.querySelector('#js-modal-inner');
const modalOverlay = document.querySelector('#js-modal-overlay');
const modalBackBtn = document.querySelector('#js-video-back');

let sourceElement;
let videoOpen;

function handleClick(event) {
  const modalOpen = event.target.closest('.js-modal-open');
  const modalClose = event.target.closest('#js-modal-close');
  const modalBox = event.target.closest('#js-modal-box');
  const galleryVideoLink = event.target.closest('.js-gallery-video-link');
  const videoBack = event.target.closest('#js-video-back');
  const videoModalOpen = event.target.closest('.js-modal-open-video');

  if (modalOpen) {
    event.preventDefault();
    openModal(modalOpen);
    return;
  }

  if (modalClose) {
    closeModal();
    return;
  }

  if (galleryVideoLink) {
    event.preventDefault();
    loadGalleryVideo(event);
    return;
  }

  if (videoBack) {
    backToGrid();
    return;
  }

  if (videoModalOpen) {
    event.preventDefault();
    openModal(videoModalOpen);
    loadVideo();
    return;
  }

  // If modal is open, and user clicks outside the box, close it.
  if (document.documentElement.classList.contains('modal-is-open') && !modalBox) {
    event.preventDefault();
    closeModal();
    return;
  }
}

function openModal(target) {
  document.documentElement.classList.add('modal-is-open');
  modalOverlay.classList.remove('is-closed');
  modalRoot.classList.remove('is-closed');
  modalRoot.removeAttribute('data-color');
  modalRoot.classList.remove('modal--note');

  // Store the source div, so we can put the content back after
  sourceElement = document.querySelector(target.hash);

  // Pass in content here
  const content = document.querySelector(target.hash + ' > div');
  modalInner.appendChild(content);

  // If modal has a slider, update it so it renders correctly
  if (content.querySelector('.glide')) {
    slider();
  }

  // Check if data-color attribute
  const color = sourceElement.dataset.color
  if (color) {
    modalRoot.setAttribute('data-color', color)
  }

  // Check if data-modifier attribute
  const modifier = sourceElement.dataset.modifier
  if (modifier) {
    if (modifier === 'note') {
      modalRoot.classList.add('modal--note')
    }
  }

  // Check if data-chart attribute
  const chart = target.dataset.chart
  if (chart) {
    animateBarChart(content)
  }
}

function animateBarChart(root) {
  const bars = root.querySelectorAll('.js-bar-group rect');
  gsap.from(bars, {
    scaleY: 0,
    duration: 0.3,
    transformOrigin:"50% bottom",
    stagger: 0.1,
    ease: "power2.out"
  });
}

function closeModal() {
  document.documentElement.classList.remove('modal-is-open');
  modalOverlay.classList.add('is-closed');
  modalRoot.classList.add('is-closed');
  // Put the content back where it came from
  sourceElement.appendChild(modalInner.querySelector('div'));

  if (videoOpen) {
    backToGrid();
    videoOpen = false;
  }
}

function loadGalleryVideo(e) {
  videoOpen = true;
  // Hide video video__grid
  document.querySelector('#js-video-grid').classList.add('is-hidden');
  // Add src to iframe and show player
  const src = e.target.closest('a').href
  const player = modalRoot.querySelector('#js-video-player')
  const iframe = player.querySelector('iframe');
  iframe.src = src;
  player.classList.remove('is-hidden');
  modalBackBtn.classList.remove('is-hidden');
}

function loadVideo() {
  // Add src to iframe and show player
  const src = modalRoot.querySelector('.js-video-link').href
  const player = modalRoot.querySelector('#js-video-player')
  const iframe = player.querySelector('iframe');
  iframe.src = src;
  player.classList.remove('is-hidden');
}

function backToGrid() {
  const player = document.querySelector('#js-video-player')
  const iframe = player.querySelector('iframe');
  iframe.src = '';
  player.classList.add('is-hidden');
  modalBackBtn.classList.add('is-hidden');
  document.querySelector('#js-video-grid').classList.remove('is-hidden');
}

export default function() {
  window.addEventListener('load', function() {
    // Click handler for modal actions
    document.addEventListener('click', handleClick, false);
  })
}
