import gsap from 'gsap'
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const htmlEl = document.documentElement
const contentEl = document.querySelector('.js-content')
const navEl = document.querySelector('#nav')

function showNav() {
  htmlEl.classList.add('nav-is-active')
  const navWidth = navEl.offsetWidth
  gsap.to(navEl, { x: 0 })
  gsap.to(contentEl, { x: -navWidth })
}

export function hideNav(skipTransition) {
  htmlEl.classList.remove('nav-is-active')
  const navWidth = navEl.offsetWidth
  if (!skipTransition) {
    gsap.to(navEl, { x: navWidth })
    gsap.to(contentEl, { x: 0, clearProps: "transform" })
  } else {
    gsap.set(navEl, { x: navWidth })
    gsap.set(contentEl, { x: 0, clearProps: "transform" })
  }
}

function goToSection(id) {
  gsap.to(window, { duration: 0.3, scrollTo: id });
}

const popupCenter = ({url, title, w, h}) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title,
      `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
    )

    if (window.focus) newWindow.focus();
}

function openLinkedInPopup(target) {
  const url = target.href;
  const w = 500;
  const h = 500;
  popupCenter({ url, title: 'LinkedIn', w, h });
}

function handleClick(event) {
  const navBtn = event.target.closest('.js-nav-button');
  const closeBtn = event.target.closest('#nav-close');
  const inPageLink = event.target.closest('.js-chapter-link');
  const linkedInShare = event.target.closest('.js-linkedin');

  if (navBtn) {
    showNav();
    return;
  }

  if (closeBtn) {
    hideNav();
    return;
  }

  if (inPageLink) {
    event.preventDefault();
    goToSection(inPageLink.hash);
    return;
  }

  if (linkedInShare) {
    event.preventDefault();
    openLinkedInPopup(linkedInShare);
    return;
  }
}

export function init() {
  window.addEventListener('load', function() {
    // Click handler for modal actions
    document.addEventListener('click', handleClick, false);
  })
}
