import axios from 'axios';
import gsap from 'gsap';
import { hideNav } from './nav';
import { setupTextTriggers } from './text';
import { initContent, resizeListener, scrollListener } from './content';

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

const loader = document.querySelector('#js-loader');
const container = document.querySelector('#js-ajax-container');

function handleClick(event) {
  const link = event.target.closest('.js-ajax-link');
  const beginLink = event.target.closest('.js-begin-link');

  if (link) {
    event.preventDefault();
    loadAjaxContent(link.href, false);
    return;
  }

  if (beginLink) {
    event.preventDefault();
    beginTransition(beginLink);
    return;
  }
}

function beginTransition(link) {
  // const container = document.querySelector('#home-container');
  gsap.timeline()
    .to('.front__btn', { y: "100%", opacity: 0, duration: 0.1 })
    .to('.turn', { opacity: 0, duration: 0.1 })
    .to(['.home__main', '.home__logo', '.home__title', '.home__corp', '.nav-btn'], { opacity: 0, duration: 0.2 })
    .to('.front__front', { x: "50%", duration: 0.3, onComplete: () => {
      document.querySelector('#front-cover').classList.add('is-open');
    }})
    .to('.front', { scale: 2, opacity: 0, duration: 1, onComplete: () => {
      loadAjaxContent(link.href, false);
    }})
}

function loadAjaxContent(url, isPopState) {
  loader.classList.remove('is-hidden');
  gsap.to(loader, {
    opacity: 1,
    duration: 0.2,
    onComplete() {
      document.documentElement.classList.add('is-loading');
      hideNav(true);
      // Remove the resize listener
      window.removeEventListener('resize', resizeListener, false);
      window.removeEventListener('scroll', scrollListener, false);

      axios.get(url)
        .then(response => {
          processAjax(response, url, isPopState);
        })
        .catch(error => console.log(error));
    }
  });
}

function processAjax(response, url, isPopState) {
  const fragment = document.createRange().createContextualFragment(response.data);
  const content = fragment.querySelector('#js-ajax-content');
  document.title = fragment.querySelector('title').innerHTML;

  container.innerHTML = '';
  container.appendChild(content);

  if (isPopState === false) {
    history.pushState({ url }, null, url);
  }

  gsap.set(window, { scrollTo: 0 });

  const images = Array.from(content.querySelectorAll('img'))
  const imageCount = content.querySelectorAll('img').length;
  let loadCount = 0;

  images.forEach((image, i) => {
    const newImage = new Image();
    newImage.onload = () => {
      if (loadCount < images.length -1) {
        loadCount++;
      } else {
        // All images have loaded!
        // So register interactions
        setupTextTriggers();
        initContent();
        document.documentElement.classList.remove('is-loading');
        gsap.to(loader, { opacity: 0, duration: 0.2, onComplete: () => {
          loader.classList.add('is-hidden');
          return
        } });
      }
    }

    if (image.srcset.length) {
      newImage.srcset = image.srcset
    } else if (image.src.length) {
      newImage.src = image.src
    }
  })

  // NOTE: The load event was being triggered by appendChild at line 89 of content.js, which caused an inifinite loop
  // document.addEventListener('load', function(event) {
  //   const elm = event.target;
  //   if (elm.nodeName.toLowerCase() === 'img'){
  //     loadCount++;
  //   }
  //   if (loadCount === imageCount) {
  //     // All images have loaded!
  //     // So register interactions
  //     setupTextTriggers();
  //     initContent();
  //     document.documentElement.classList.remove('is-loading');
  //     gsap.to(loader, { opacity: 0, duration: 0.2, onComplete: () => {
  //       loader.classList.add('is-hidden');
  //     } });
  //   }
  // }, true);
}

function handlePop(event) {
  loadAjaxContent(event.state.url, true);
}

export default function() {
  document.addEventListener('click', handleClick);
  window.addEventListener('popstate', handlePop);

  // Initialise history state with current URL
  const url = location.href;
  history.replaceState({ url }, null, url);
}
