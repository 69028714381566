let textTriggers

function mouseHandler(e) {
  const target = document.querySelector('#' + e.target.dataset.target)
  if (e.type === 'mouseenter') {
    target.classList.add('target-active');
  } else {
    target.classList.remove('target-active');
  }
}

export function setupTextTriggers() {
  textTriggers = Array.from(document.querySelectorAll('.js-text-trigger'))
  if (textTriggers.length) {
    textTriggers.forEach(trigger => {
      trigger.addEventListener('mouseenter', mouseHandler)
      trigger.addEventListener('mouseleave', mouseHandler)
    })
  }
}


export default function() {
  window.addEventListener('load', function() {
    setupTextTriggers();
  })
}
