import { init as nav } from './modules/nav'
import content from './modules/content'
import text from './modules/text'
import modal from './modules/modal'
import slider from './modules/slider'
import ajax from './modules/ajax'

nav()
content()
text()
modal()
ajax()
