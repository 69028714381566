import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import slider from './slider'

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

gsap.registerPlugin(ScrollTrigger);
gsap.config({force3d: true});

const MOBILE = 960

let turnBtn
let leftBlocks
let rightBlocks
let desktopContainer
let mobileContainer
let pageLeft
let pageRight
let rightContainer
let chapterCover

let leftHeight = 0
let rightHeight = 0
let windowHeight = 0
let scrollHeight = 0
let diff = 0
let coEf = 0
// Set to zero so resizeHandler works on desktop on initial page load
let lastWidth = 0
let currentWidth = 0
let lastScrollPos = 0
let ticking = false;

// Setup a timer
let timeout;

function initResizeListener() {
  // Listen for resize events
  window.addEventListener('resize', resizeListener, false)
}

export function resizeListener() {
  // If there's a timer, cancel it
  if (timeout) {
    window.cancelAnimationFrame(timeout)
  }
  // Setup the new requestAnimationFrame()
  timeout = window.requestAnimationFrame(function() {
    // Check window width has actually changed
    currentWidth = window.innerWidth
    if (currentWidth !== lastWidth) {
      // Run our resize functions
      resizeHandler()
      updateScrollVars();
      updateRightPos();
      lastWidth = window.innerWidth
    }
  })
}

function resizeHandler() {
  // Check if you actually need to move the blocks or if they're already in the right place
  if (currentWidth >= MOBILE && lastWidth < MOBILE) {
    positionBlocksDesktop()
    // Add scroll triggers if bigger than mobile
    if (chapterCover) {
      ScrollTrigger.getAll().forEach(ST => ST.disable());
      setupScrollTriggers();
    }
  } else if (currentWidth < MOBILE && lastWidth >= MOBILE) {
    positionBlocksMobile()
    // Disable stroll triggers if mobile
    if (chapterCover) {
      ScrollTrigger.getAll().forEach(ST => ST.disable());
      setupMobileScrollTriggers();
    }
  } else if (currentWidth < MOBILE && lastWidth < MOBILE) {
    // This one runs on page load on mobile
    if (chapterCover) {
      setupMobileScrollTriggers();
    }
  }
}

function updateScrollVars() {
  leftHeight = pageLeft.scrollHeight
  rightHeight = pageRight.scrollHeight
  windowHeight = window.innerHeight
  scrollHeight = leftHeight - windowHeight
  diff = rightHeight - leftHeight
  coEf = diff/scrollHeight
}

function positionBlocksMobile() {
  leftBlocks.forEach((block, i) => {
    block.insertAdjacentElement('afterend', rightBlocks[i])
  })
}

function positionBlocksDesktop() {
  rightBlocks.forEach(block => desktopContainer.appendChild(block))
}

function updateRightPos() {
  // Check if page has cover
  // And only trigger this if we've scrolled past cover animation
  if (chapterCover && lastScrollPos > windowHeight) {
    gsap.to(rightContainer, {
      // Add windowHeight offset to counter cover animation
      y: (-lastScrollPos * coEf) + (windowHeight * coEf)
    })
  } else if (!chapterCover) {
    gsap.to(rightContainer, {
      y: -lastScrollPos*coEf
    })
  }
}

function initScroll() {
  leftHeight = pageLeft.scrollHeight
  rightHeight = pageRight.scrollHeight
  windowHeight = window.innerHeight
  scrollHeight = leftHeight - windowHeight
  diff = rightHeight - leftHeight
  coEf = diff/scrollHeight
  pageRight.classList.add('page--hide-overflow')

  // This scroll event handles the RHS offset
  window.addEventListener('scroll', scrollListener, false);
}

export function scrollListener() {
  lastScrollPos = window.scrollY

  if (!ticking) {
    window.requestAnimationFrame(function() {
      updateRightPos();
      ticking = false;
    })

    ticking = true;
  }
}

function setupMobileScrollTriggers() {
  gsap.set('#page-left-container', { opacity: 1 })

  const coverTl = gsap.timeline()
    .to('#chapter-cover .pages', { rotationY: -90 })
    .to(chapterCover, { opacity: 0, duration: 0.1 })
    .set(chapterCover, { display: 'none'})

  ScrollTrigger.create({
    animation: coverTl,
    trigger: chapterCover,
    start: 'top top',
    end: 'bottom +=50%',
    scrub: true,
    pin: true,
    pinSpacing: false
  })
}

function setupScrollTriggers() {
  gsap.set('#page-left-container', { opacity: 0 })

  const coverTl = gsap.timeline()
    .add('start')
    .to('.page-turn__content', { opacity: 0, duration: 0.1 }, 'start')
    .set(chapterCover, { pointerEvents: 'none'})
    .to('#chapter-cover .page--right', { rotationY: -180 }, 'start')
    .to(chapterCover, { opacity: 0, duration: 0.1 })
    .set(chapterCover, { display: 'none'})

  ScrollTrigger.create({
    animation: coverTl,
    trigger: chapterCover,
    start: 'top top',
    end: 'bottom +=50%',
    scrub: true,
    pin: true,
    pinSpacing: false
  })

  const contentsTl = gsap.timeline()
    .add('start')
    .to('#page-left-container', { opacity: 1 }, 'start')
    .to('.pages__tab', { y: 0 }, 'start')

  ScrollTrigger.create({
    animation: contentsTl,
    trigger: chapterCover,
    start: '+=75% +=75%',
    end: '+=200',
    scrub: true
  })
}

function scrollStart() {
  gsap.to(window, { duration: 0.3, scrollTo: pageLeft });
}

export function initContent() {
  turnBtn = document.querySelector('#turn-button')
  leftBlocks = Array.from(document.querySelectorAll('.js-block-left'))
  rightBlocks = Array.from(document.querySelectorAll('.js-block-right'))
  desktopContainer = document.querySelector('#desktop-blocks')
  mobileContainer = document.querySelector('#mobile-blocks')
  pageLeft = document.querySelector('#page-left')
  pageRight = document.querySelector('#page-right')
  rightContainer = document.querySelector('#page-right-container')
  chapterCover = document.querySelector('#chapter-cover')
  pageLeft = document.querySelector('#page-left')

  currentWidth = window.innerWidth
  lastScrollPos = window.scrollY

  // I think we need to do this again to trigger the resizeHandler on ajax load
  lastWidth = 0

  if (pageLeft) {
    slider()
    resizeHandler()
    initResizeListener()
    initScroll()
  }

  if (turnBtn) {
    turnBtn.addEventListener('click', scrollStart);
  }
}

export default function() {
  window.addEventListener('load', function() {
    initContent()
  })
}
