import Glide, { Controls, Breakpoints } from '@glidejs/glide/dist/glide.modular.esm';

export default function() {
  const carousels = Array.from(document.querySelectorAll('.glide'))

  if (carousels.length) {
    carousels.forEach(carousel => {
      const slider = new Glide(carousel, {
        type: "carousel"
      });
      slider.mount({ Controls, Breakpoints });
    })
  }
}
